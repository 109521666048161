import api from "./api";
import axios from '../utils/axios';
import TokenService from "../services/token.service"
import { SERVER_API } from "../config/config";

// utils
import { isValidToken, setSession } from '../utils/jwt';
import { errorAlert } from "../pages/admin/layout/Alert";

class partnerApi {


    ChangePassword = async (adminId, oldPassword, newPassword) => {
        const response = await api
            .post("/auth/change-password", {
                 adminId, oldPassword, newPassword
            });
        // console.log("response",response)
        if (response.data) {
            return response.data;
        }
        if(!response.data.status){

            errorAlert(response.data.message)
          }
    };

    login = async (number='9876543210', userType='NUMBER') => {

        const response = await axios.post('/owners/login', {
            number,
            
            userType,
            
        });

        const userData = response.data;
        if(!response.data.status){

            errorAlert(response.data.message)
          }

        return userData;
      };

    register = async (phoneNumber='', authType='NUMBER') => {
    const response = await axios.post('/users/send-signup-v2', {
        phoneNumber,
        authType,
    });

    const {status} = response.data
    if(!response.data.status){

        errorAlert(response.data.message)
      }
    console.log("res ===>", status)
    return status;
    };

    verifyOtp = async (phoneNumber, otp,option) => {
        const response = await axios.get('/owner/verify-otp', {
           params: {
               phoneNumber,
               otp,
           }
        })
        if(!response.data.status){
            errorAlert(response.data.message)
            return response.data;
        }else{
            return response.data;
        }
    };
    
    partnerSignup = async (partnerDetails) => {
        const response = await api
            .post("/owners/register", {...partnerDetails
            });
            if(!response.data.status){
                errorAlert(response.data.message)
            }
        if (response.data) {
            return response.data;
        }
        
    };

}

export default new partnerApi();